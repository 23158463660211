import '@/commons';
import Vue from 'vue';
import store from '@/store';
import { onMounted, ref, onBeforeMount } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import vuetify from '@/config/plugins/vuetify';
import Footer from '@/layout/footer';
import Menu from '@/layout/menu';
import Carga from '@/layout/carga';
import { toggles } from '@/observable';
import 'intersection-observer'; // for cross-browser support
import Scrollama from 'vue-scrollama';
import bodymovin from 'lottie-web';

const footer = Footer;
const menu = Menu;
const carga = Carga;

function elementInViewport(el: any) {
  let top = el.offsetTop;
  const height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
  }

  return (
    top >= window.pageYOffset &&
    (top + height) <= (window.pageYOffset + window.innerHeight)
  );
}

// The main handler of highlighting appropriate nav items
const navigation = Vue.component('navigation', {
  name: 'navigation',
  props: {
    idBlocks: Array,
  },
  data() {
    return {
      activeBlock: null,
    };
  },
  created() {
    document.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.idBlocks.find((number: any) => {
        const el = document.getElementById(number);
        if (el != null) {
          if (elementInViewport(el)) {
            this.activeBlock = number;
            if (this.activeBlock! > 0) {
              this.$emit('fijar', true);
            } else {
              this.$emit('fijar', false);
            }
            if (this.activeBlock! >= 5) {
              this.$emit('quitar', true);
            } else {
              this.$emit('quitar', false);
            }
          }
        }
      });
    },
  },
  template: `<span></span>`,
});

const Index = Vue.extend({
  components: {navigation, Scrollama},
  setup() {
    const cargado = ref(false);
    const aparecer = ref(false);
    const proyectos = ref(false);
    const servicios = ref(false);
    const palabrasLength = ref(4);
    const palabras = ref(0);
    const fijo = ref(false);
    const hide = ref(false);
    const adds = ref(false);
    const addsMov = ref(false);
    const gif = ref(false);
    const android = ref(false);
    const ios = ref(false);

    const appleDig = ref(false);
    const adrDig = ref(false);
    const fbApple = () => {
      appleDig.value = !appleDig.value;
    };
    const fbAndr = () => {
      adrDig.value = !adrDig.value;
    };

    const handleScroll = () => {
      if (window.pageYOffset >= 2000) {
        gif.value = true;
      } else {
        gif.value = false;
      }
    };
    document.addEventListener('scroll', handleScroll);

    const toggleForm = () => {
      store.commit('toggleContactForm');
    };

    const ToggleAdd = () => {
      adds.value = !adds.value;
    };

    const quitar = (e: boolean) => {
      hide.value = e;
    };
    const fijar = (e: boolean) => {
      fijo.value = e;
    };

    const toggleProyectos = () => {
      proyectos.value = !proyectos.value;
      aparecer.value = !aparecer.value;
    };
    const toggleServicios = () => {
      servicios.value = !servicios.value;
      aparecer.value = !aparecer.value;
    };

    setInterval(() => {
      if (++palabras.value >= palabrasLength.value) {
          palabras.value = 0;
      }
    }, 1000);

    onMounted(() => {
      AOS.init();
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
          android.value = true;
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
          ios.value = true;
      } else {
          android.value = false;
          ios.value = false;
      }
      toggles.listo = !toggles.listo;
      setTimeout(() => {
        const window = document.getElementById('ventana')!;
        const alto = window.offsetHeight;
        window.style.height = `${alto}px`;
      }, 1500);

      setInterval(() => {
        const random = Math.floor(Math.random() * 5) + 0;
        const bigSize = [
          'url(/assets/images/index/bg1.jpg)',
          'url(/assets/images/index/bg2.jpg)',
          'url(/assets/images/index/bg3.jpg)',
          'url(/assets/images/index/bg4.jpg)',
          'url(/assets/images/index/bg5.jpg)',
        ];
        const bigSizeMd = [
          'url(/assets/images/index/bg1-v1.jpg)',
          'url(/assets/images/index/bg2-v1.jpg)',
          'url(/assets/images/index/bg3-v1.jpg)',
          'url(/assets/images/index/bg4-v1.jpg)',
          'url(/assets/images/index/bg5-v1.jpg)',
        ];
        if (screen.width <= 800) {
          (document.getElementById('seccion1') as any).style.backgroundImage = bigSizeMd[random];
          // (document.getElementById('fondo') as any).style.backgroundImage = bigSizeMd[random];
          // (document.getElementById('fondo1') as any).style.backgroundImage = bigSizeMd[random];
          // (document.getElementById('fondo2') as any).style.backgroundImage = bigSizeMd[random];
          // (document.getElementById('fondo3') as any).style.backgroundImage = bigSizeMd[random];
        } else if (screen.width <= 1024) {
          (document.getElementById('seccion1') as any).style.backgroundImage = bigSize[random];
          // (document.getElementById('fondo') as any).style.backgroundImage = bigSizeMd[random];
          // (document.getElementById('fondo1') as any).style.backgroundImage = bigSizeMd[random];
          // (document.getElementById('fondo2') as any).style.backgroundImage = bigSizeMd[random];
          // (document.getElementById('fondo3') as any).style.backgroundImage = bigSizeMd[random];
        } else {
          (document.getElementById('seccion1') as any).style.backgroundImage = bigSize[random];
          (document.getElementById('fondo') as any).style.backgroundImage = bigSize[random];
          (document.getElementById('fondo1') as any).style.backgroundImage = bigSize[random];
          (document.getElementById('fondo2') as any).style.backgroundImage = bigSize[random];
          (document.getElementById('fondo3') as any).style.backgroundImage = bigSize[random];
          (document.getElementById('fondo4') as any).style.backgroundImage = bigSize[random];
        }
      }, 4000);


      const bg = document.getElementById('bg-azul')!;
      const photo = document.getElementById('seccion1')!;

      setTimeout(() => {
        aparecer.value = true;
      }, 2000);

      setTimeout(() => {
        cargado.value = true;
      }, 1700);

      setTimeout(() => {
        bg.classList.add('encojer');
        photo.classList.add('encojer-photo');
      }, 700);

      setTimeout(() => {
        photo.classList.add('subir-photo');
      }, 600);

      setTimeout(() => {
        bg.classList.add('subir-bg');
      }, 300);

    });
    const toggleMenu = () => {
      toggles.menu = !toggles.menu;
    };
    return {
      adds,
      addsMov,
      ToggleAdd,
      toggleMenu,
      cargado,
      aparecer,
      servicios,
      proyectos,
      toggleProyectos,
      toggleServicios,
      palabrasLength,
      palabras,
      fijo,
      fijar,
      hide,
      quitar,
      toggleForm,
      gif,
      android,
      ios,
      appleDig,
      adrDig,
      fbApple,
      fbAndr,
    };
  },
});
export default new Index({
  el: '#index',
  vuetify,
  delimiters: ['[{', '}]'],
  components: { navigation },
});
